import * as React from "react";
import _ from "lodash";
import { FC } from 'react';
import { Meeting } from "../../model/meeting";
import { Rx } from "../../services/Rx";
import { MeetingHeader } from "./MeetingHeader";
import { SpeakRange } from "./SpeakRange";
import { TextRange } from "./TextRange";
import UrlReader from "../../services/UrlReader";
import "./MeetingPage.css";
import {
	Divider,
	FormControlLabel,
	Paper,
	Switch,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button
} from "@material-ui/core";
import { ColoredPlayer } from "./ColoredPlayer";
import { VideoPlayer } from "./VideoPlayer";
import { Range } from "./ColoredSeekBar";
import IndexedListColorManager from "../../services/IndexedListColorManager";
import WordsManager from "../../services/WordsManager";
import Firebaser from "../../services/Firebaser";
import LazyLoad from "react-lazyload";
import { Alert } from "@material-ui/lab";
import { useSubscription } from "../common/useSubscription";
import {Word} from "../../model/word";
import TImeManager from "../../services/TImeManager";
import { useParams } from "react-router-dom";

export const MeetingPage: FC = () => {
	const [meeting, setMeeting] = React.useState<Meeting>();
	const currentTime = useSubscription(Rx.CurrentTime);
	const [isSubtitles, setIsSubtitles] = React.useState(false);
	const { id: meetingId } = useParams();
	const [isWorking, setIsWorking] = React.useState(false);
	const [isMeetingLoaded, setIsMeetingLoaded] = React.useState(false);
	const [showReadyValidationDialog, setShowReadyValidationDialog] = React.useState(false);

	React.useEffect(() => {
		Rx.LTR.next(false);

		Rx.ChosenMeeting.subscribe(meeting => {
			if (!meeting) return;
			setMeeting(meeting)
			setIsSubtitles(meeting.metadata.isSubtitles)
		});
		if (!!Rx.ChosenMeeting.value)
			return;

		Firebaser.getMeeting(meetingId);
        Firebaser.getAllUsers();
	}, []);

	React.useEffect(() => {
		const autoSaveInterval = setInterval(WordsManager.saveChangedMeeting, 60000)
		return () => {
			window.clearInterval(autoSaveInterval);
		}
	}, []);

	React.useEffect(() => {
		if (meeting && meeting.words && !isMeetingLoaded) {
			if (meeting.metadata.dateOfReady 
				&& !_.isEmpty(meeting.metadata.dateOfReady)
				&& meeting.metadata.dateOfReady[0].value === true) {
				const overlaps = WordsManager.validateOverlaps(meeting, ranges || []);
				if (overlaps.length > 0) {
					setShowReadyValidationDialog(true);
				}
			}
			setIsMeetingLoaded(true);
		}

	}, [meeting]);

	const getRangeSize = (index: number) => {
		const afterRangeIndex = index === ranges.length - 1
			? meeting?.words?.length : ranges[index + 1];

		if (!afterRangeIndex)
			return -1;

		return afterRangeIndex - ranges[index];
	};

	const getTotalTime = () => !!meeting ? meeting.metadata.lengthInSecs : -1;

	const getSoundRangeFromIndexRange = (rangeStartIndex: number): Range | null => {
		if (!meeting)
			return null;

		const words = meeting.words;
		const speaker = WordsManager.getSpeakerName(words[rangeStartIndex], meeting.metadata);
		const speakerColor = IndexedListColorManager.getColorByIndex(!speaker ?
			-1 : Array.from(meeting.metadata.participants).findIndex(p => p.trim() === speaker.trim()));

		return {
			start: words[rangeStartIndex].start,
			speaker: speaker,
			color: speakerColor
		};
	};

	const ltr = useSubscription(Rx.LTR);

	if (!meeting)
		return <></>;

	const { ranges, timeRanges } = WordsManager.generateRanges(meeting);
	const overlaps = WordsManager.validateOverlaps(meeting, ranges);

	const changeRangeIndex = ({
								  wordIndex,
								  rangeIndex,
								  words = meeting.words,
								  newRange,
								} : {
									wordIndex: number;
									rangeIndex: number;
									words?: Word[],
									newRange: boolean
								}) => {
		const updatedMeetingWords: Word[] = meeting.words;
		// updatedMeetingWords[wordIndex].start = currentTime; // Setting currentTime as range start time when enter
		while (updatedMeetingWords[wordIndex].range_ix === rangeIndex) {
			updatedMeetingWords[wordIndex].range_ix = updatedMeetingWords[wordIndex].range_ix + 1;
			wordIndex++;
			if (wordIndex === updatedMeetingWords.length) break;
		}

		if (newRange) {
			for (let i = wordIndex; i < words.length; i++) {
				updatedMeetingWords[i].range_ix = updatedMeetingWords[i].range_ix + 1;
			}
		}

		(Rx.ChosenMeeting.value as Meeting).words = updatedMeetingWords;
		Rx.ChangesToSave.next(true);
		setMeeting({...meeting, words: updatedMeetingWords})
		// Rx.ChosenMeeting.next({...meeting, words:updatedMeetingWords})
	}

	const breakRange = (rangeIndex: number, rangeWordIndex: number, wordCharIndex: number, newRange: boolean) => {
		let wordIndex = rangeWordIndex + ranges[rangeIndex];
		if (wordCharIndex === 0) {
			// Character is in start of word
			changeRangeIndex({wordIndex, rangeIndex, newRange});
			return;
		}

		if (meeting.words[wordIndex].text.length === wordCharIndex) {
			// Character is in end of word, need to start from next word
			changeRangeIndex({wordIndex: wordIndex + 1, rangeIndex, newRange});
			return;
		}

		// Character is in the middle, breaking word
		let updatedMeetingWords: Word[] = meeting.words;
		let word = updatedMeetingWords[wordIndex];
		let newWord = _.clone(word);
		word.text = word.text.slice(0, wordCharIndex);
		word.word = word.text;
		newWord.text = newWord.text.slice(wordCharIndex, newWord.text.length);
		newWord.word = newWord.text;
		updatedMeetingWords.splice(wordIndex + 1, 0, newWord);

		setMeeting({...meeting, words: updatedMeetingWords})

		changeRangeIndex({wordIndex: wordIndex + 1, rangeIndex, words: updatedMeetingWords, newRange})
	}

	const mergeRanges = (rangeIndex: number, mergeWithNextRange: boolean = false) => {
		const words = meeting.words;
		const rangeFirstWordIndex = mergeWithNextRange
			? ranges[rangeIndex+1] // Merging current range (rangeIndex) with the next one (rangeIndex+1), using enter key
			: ranges[rangeIndex]; // Merging current range (rangeIndex) with previous range (rangeIndex-1), using backspace

		for (let i = rangeFirstWordIndex; i <= words.length - 1; i++) {
			words[i].range_ix = words[i].range_ix - 1;
		}

		(Rx.ChosenMeeting.value as Meeting).words = words;
		Rx.ChangesToSave.next(true);
		setMeeting({...meeting, words: words})
		Rx.ChosenMeeting.next({...meeting, words})
	}

	const updateWordTime = (wordIndex: number, timeString: string, position: string) => {
		let updatedMeetingWords: Word[] = meeting.words;
		const timeInSecs = TImeManager.getTimeNumberFromString(timeString)
		updatedMeetingWords[wordIndex] = {
			...updatedMeetingWords[wordIndex],
			[position]: timeInSecs
		};

		Rx.ChangesToSave.next(true);
		(Rx.ChosenMeeting.value as Meeting).words = updatedMeetingWords;
		setMeeting({...meeting, words: updatedMeetingWords})
		Rx.ChosenMeeting.next({...meeting, words: updatedMeetingWords})
	}

	const getCurrentRangeIndex = () => {
		if (!timeRanges) return;
		// @ts-ignore
		if (currentTime > _.last(timeRanges)) {
			return ranges.length -1
		}
		const matchingRanges = timeRanges.filter((range: {start: number, end: number}) => range.start <= currentTime && range.end >= currentTime).map(range => range.rangeIndex);
		return matchingRanges;
	}

	const getCurrentRangeWords = () => {
		//@ts-ignore
		const currentRangeIndex: number[] = getCurrentRangeIndex();
		if (_.isEmpty(currentRangeIndex) || _.isNil(currentRangeIndex)) {
			return [];
		}
		return _.map(currentRangeIndex, (rangeIndex: number) => {
			const firstWordRangeIndex = ranges[rangeIndex];
			const lastWordRangeIndex = ranges[rangeIndex+1] -1;
			const rangeWords = meeting.words.slice(firstWordRangeIndex, firstWordRangeIndex == _.last(ranges) ? meeting.words.length+1 : lastWordRangeIndex+1);
			return rangeWords
		})
	}

	const handleIsSubtitlesToggle = (value: boolean) => {
		setIsSubtitles(value)
		const updatedMeeting = Rx.ChosenMeeting.value;
		if (updatedMeeting && updatedMeeting.metadata) {
			updatedMeeting.metadata.isSubtitles = value;
			setMeeting(updatedMeeting)
		}
	}


	const soundRanges = ranges.map(getSoundRangeFromIndexRange).filter(x => !!x).map(x => x as Range);

	const setReadyDate = async (value: boolean, validationDialog: boolean) => {
		if (!meeting) return;
		setIsWorking(true);
		Rx.SavingAlert.next(true);
		try {
			const currentReady = _.isEmpty(meeting.metadata.dateOfReady) ? false : meeting.metadata.dateOfReady && meeting.metadata.dateOfReady[0];
			const shouldSetReady = value || currentReady === false || currentReady && currentReady.value === false;

			const readyNewValue = [{
				value: shouldSetReady,
				uid: Rx.ConnectedUserName.value,
				timestamp: Date.now(),
				validationDialog,
			}, ...meeting.metadata.dateOfReady]

			await Firebaser.setReady(meeting.metadata.id, meeting.metadata.assignedTo, readyNewValue);
			meeting.metadata.dateOfReady = readyNewValue;
			Rx.ChosenMeeting.next({ ...meeting });
			Rx.SavingAlert.next(false);
			Rx.SavedAlert.next(true);
		}
		catch (err) {
			Rx.SavingFailed.next(true);
		}
		finally {
			setIsWorking(false)
		}
	}

	const setDeliveredToClient = async (meeting: Meeting, validationDialog: boolean) => {
		setIsWorking(true);
		Rx.SavingAlert.next(true);
		try {
			const newValue = !meeting?.metadata.deliveredToClient;
			// if (!newValue) {
			// 	await setReadyDate(false);
			// }

			const currentDelivered = _.isEmpty(meeting.metadata.deliveredToClient) ? false : meeting.metadata.deliveredToClient && meeting.metadata.deliveredToClient[0];
			const shouldSetDelivered = currentDelivered === false || currentDelivered && currentDelivered.value === false;

			const deliveredNewValue = [{
				value: shouldSetDelivered,
				uid: Rx.ConnectedUserName.value,
				timestamp: Date.now(),
				validationDialog,
			}, ...meeting.metadata.deliveredToClient]

			await Firebaser.setDelivered(meeting?.metadata.id, deliveredNewValue, meeting?.metadata.assignedTo);
			meeting.metadata.deliveredToClient = deliveredNewValue;
			Rx.Meetings.next([...Rx.Meetings.value]);
			Rx.ChosenMeeting.next({ ...meeting });
			Rx.SavingAlert.next(false);
			Rx.SavedAlert.next(true);
		} catch (err) {
			Rx.SavingFailed.next(true);
		}
		finally	{
			setIsWorking(false);
		}
	}

	return (
		<>
			{!_.isEmpty(meeting.videoSources)
				? <VideoPlayer videoSources={meeting.videoSources} currentRangeWords={getCurrentRangeWords()}/>
				: null}
			<Paper square elevation={3} id="meetingPage">
				<MeetingHeader overlaps={overlaps.length}/>
				<Divider />
				{ranges.map((rangeStart, i) =>
					<LazyLoad height={100}
							  offset={1200}
							  scrollContainer="#meetingPage"
							  unmountIfInvisible={true}
							  key={i}>
						<div className="columnFlex">
							<TextRange firstWordIndex={rangeStart}
									   isSubtitles={isSubtitles}
									   rangeSize={getRangeSize(i)}
									   rangesCount={ranges.length}
									   breakRange={(rangeWordIndex, wordCharIndex, newRange) => breakRange(i, rangeWordIndex, wordCharIndex, newRange)}
									   mergeRanges={((mergeWithNextRange) => mergeRanges(i, mergeWithNextRange))}
									   updateWordTime={updateWordTime}
									   rangeIndex={i}
									   isPassed={isSubtitles && timeRanges[i] && (Number(currentTime) > Number(timeRanges[i].end))}
									   overlapping={overlaps.includes(i)}/>
						</div>
					</LazyLoad>
				)}
				<ColoredPlayer
					ranges={soundRanges}
					audioSources={meeting.audioSources}
					totalTime={getTotalTime()}
					isVideo={!_.isEmpty(meeting.videoSources)}/>
				{/* <EditorActions /> */}
				<FormControlLabel
					className='subtitlesToggle'
					control={<Switch
						checked={isSubtitles}
						onChange={() => {
							handleIsSubtitlesToggle(!isSubtitles)
						}}
					/>}
					label="מצב כתוביות"
				/>
				<FormControlLabel
					className='ltrSwitch'
					control={<Switch
						checked={ltr}
						onChange={e => Rx.LTR.next(e.target.checked)}
					/>}
					label="שמאל לימין"
				/>
			</Paper>
			<Dialog
				open={showReadyValidationDialog}
				onClose={() => {
					if (isWorking) return;
					setShowReadyValidationDialog(false)
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">נמצאו {overlaps.length} שגיאות</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						פגישה זו מסומנת כ״מוכן לביקורת״ אך נמצאו {overlaps.length} שגיאות.
						האם ברצונך לבטל ״מוכן לביקורת״?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowReadyValidationDialog(false)}
							color="secondary"
							disabled={isWorking}>
						המשך לעריכת החדר
					</Button>
					<Button onClick={async () => {
						if (meeting?.metadata.deliveredToClient 
							&& !_.isEmpty(meeting.metadata.deliveredToClient)
							&& meeting.metadata.deliveredToClient[0].value === true) await setDeliveredToClient(meeting, true);
						await setReadyDate(false, true);
						setShowReadyValidationDialog(false);
					}}
							color="primary"
							autoFocus
							disabled={isWorking}>
						אשר
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
